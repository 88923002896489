import React from "react"
import bbLogo from "../images/icon-384x384.png";
import ReactGA from "react-ga";
import Button from "react-bootstrap/Button";

interface AboutState {
}

interface AboutProps {

}

export default class About extends React.Component<AboutProps, AboutState> {
    constructor(props: AboutProps) {
        super(props)

        this.state = {}
    }

    async componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }

    render(): React.ReactNode {
        return <div className="container pricing-bg layout-bg pr-0 pl-0 align-self-center justify-content-center">
            <div className="container inner-about d-flex pl-0 pr-0 mission-row">
                <div className="row justify-content-center align-self-center pt-3 pb-3 mission-inner">
                    <div className="col-md-6 align-self-center justify-content-center">
                        <h1 className="logo-header">
                            <img
                                className="img-logo"
                                src={bbLogo}
                                alt="protegee-logo"
                            />
                        </h1>
                        <h1 className="p-about">Our mission</h1>
                        <p className="p-about">
                            Success is only defined by you. Protégée is simply the tool to provide accessible,
                            meaningful guidance, support and community for starting, continuing, and achieving
                            goals.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container inner-about d-flex about-row pl-0 pr-0 about-bg">
                <div className="row justify-content-center align-self-center pt-3 pb-3 about-col">
                    <div className="col-md-6">
                        <h2 className="p-about">About us</h2>
                        <p className="p-about">
                            Protégée is a project created to help others achieve their goals. Just as we have
                            achieved our goals, we want to provide a platform for ultimate success. Protegee will not only be your
                            career coach and guide, but will provide a community of other goal-driven individuals to
                            support you in your journey to success.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container inner-about pr-0 pl-0 d-flex expect-row">
                <div className="row justify-content-center align-self-center pt-3 pl-1 pr-1">
                    <div className="col-md-6">
                        <h2 className="p-about">What to expect</h2>
                        <p className="p-about">
                            A simple, easy-to-use, personalized plan with step-by-step guidance for your own goals.
                        </p>
                        <div>
                            <Button className="mb-5 price-btn shadow" href="/">Get started</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

