import React from "react";
import {Route, Switch} from "react-router-dom";
import About from "./modules/About";
import Home from "./modules/Home";
import Startup from "./modules/Startup";

interface RouteProps {
}

export const Routes: React.SFC<RouteProps> = (childProps) =>
    <Switch>
        <Route path="/" exact component={Home} props={childProps}/>
        <Route path="/about" exact component={About} props={childProps}/>
        <Route path="/startup" exact component={Startup} props={childProps}/>
    </Switch>;