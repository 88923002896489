import React from 'react';
import './App.css';
import Layout from "./components/Layout";
import {withRouter} from "react-router-dom";
import {Routes} from "./Routes";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history'

ReactGA.initialize('UA-168086093-1')
const browserHistory = createBrowserHistory()
browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search)
})

interface AppState {
}

interface AppProps {
    location: any;
}

class App extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <Layout location={this.props.location}>
                <Routes/>
            </Layout>
        )
    }
}

export default withRouter(App as any);