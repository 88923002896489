import React from "react"
import ReactGA from "react-ga";
import {ReactTypeformEmbed} from "react-typeform-embed";

interface StartupState {
}

interface StartupProps {

}

export default class Startup extends React.Component<StartupProps, StartupState> {
    constructor(props: StartupProps) {
        super(props)

        this.state = {}
    }

    async componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }

    render(): React.ReactNode {
        return <div>
            <ReactTypeformEmbed
                url={"https://adapperunicorn.typeform.com/to/uWvO96"}
            />
        </div>
    }
}

