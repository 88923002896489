import React from "react"
import "./Layout.css"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"

interface LayoutProps {
    children: React.ReactNode;
    location: any;
}
interface LayoutState {
    isNavOpen: boolean;
}

export default class Layout extends React.Component<LayoutProps, LayoutState> {
    constructor(props: LayoutProps) {
        super(props);
        this.state = { isNavOpen: false };
    }

    toggleCollapse = () => {
        this.setState({isNavOpen: !this.state.isNavOpen});
    };

    render(): React.ReactNode {
        const navBg = this.state.isNavOpen ? "nav-bg" : "";
        const path = this.props.location.pathname;
        const variant = (path !== "/") ? "light" : "dark";

        console.log(path);

        return (
            <div>
                <Navbar collapseOnSelect={true} variant={variant} expand="md" className={`mb-3 ${navBg}`}>
                    <Navbar.Toggle onClick={this.toggleCollapse}/>
                    <Navbar.Collapse>
                        <Nav>
                            {path !== "/" && <Nav.Link onClick={this.toggleCollapse} href="/">Become a Protégée</Nav.Link>}
                            <Nav.Link onClick={this.toggleCollapse} href="/startup">Startup</Nav.Link>
                            <Nav.Link onClick={this.toggleCollapse} href="/about">About</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div>
                    <main>{this.props.children}</main>
                </div>
            </div>
        )
    }
}
