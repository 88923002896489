import React from "react"
import {ReactTypeformEmbed} from "react-typeform-embed";
import ReactGA from "react-ga";


interface HomeState {
}

interface HomeProps {

}

export default class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: HomeProps) {
        super(props)

        this.state = {}
    }

    async componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }

    render(): React.ReactNode {
        return <div>
            <ReactTypeformEmbed
                url={"https://adapperunicorn.typeform.com/to/SMWiOm"}
            />
        </div>
    }
}

